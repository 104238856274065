import * as React from "react"

const SvgComponent = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 4.75h5v15h-5v-15ZM2 19.75l14 3.75V1L2 4.75v15Zm3.5-12.5h2.225l1.25 2.775 1.3-2.775h2.175l-2.275 3.975 2.5 4.675H10.45l-1.475-3.3-1.45 3.3H5.35l2.425-4.6L5.5 7.25Z"
      fill="#13171C"
      fillOpacity={0.6}
    />
  </svg>
)

export default SvgComponent
