import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import ColoredHeader from "../../components/coloredHeader"
import FloatingSearch from "../../components/floatingSearch"
import DividendHistory from "./dividend-history.json"
import { format, parse } from "date-fns"
import atlas_lg_blackwhite from "../../../static/background_images/atlas_lg_blackwhite.jpg"
import {
  Button,
  ButtonGroup,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import ExcelIcon from "../../../static/icons/ExcelIcon"
import acc from "accounting"
import ScrollToTop from "../../components/ScrollToTop"
import { window } from "browser-monads"

const DividendExcel = "/documents/eog-dividend-history.xlsx"

const formatDate = string => {
  if (!string) return ""
  try {
    const date = parse(string, "mm/dd/yy", new Date())
    return format(date, "mm/dd/yyyy", new Date())
  } catch (e) {
    return string
  }
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

const FullSizeDividendGrid = ({ dividendData }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>DECLARATION DATE</TableCell>
          <TableCell>RECORD DATE</TableCell>
          <TableCell>PAYMENT DATE</TableCell>
          <TableCell style={{ textAlign: "right" }}>AMOUNT ($)</TableCell>
          <TableCell style={{ textAlign: "center" }}>TYPE</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dividendData.map((row, i) => {
          if (row.Notes) {
            return (
              <TableRow key={i}>
                <TableCell style={{ textAlign: "center" }} colSpan={5}>
                  <div className="notes">{row.Notes}</div>
                </TableCell>
              </TableRow>
            )
          } else {
            return (
              <TableRow key={i}>
                <TableCell>{formatDate(row["Declaration Date"])}</TableCell>
                <TableCell>{formatDate(row["Record Date"])}</TableCell>
                <TableCell>{formatDate(row["Payment Date"])}</TableCell>
                <TableCell
                  style={{
                    whiteSpace: "pre",
                    textAlign: "right",
                  }}
                >
                  {acc.formatColumn([row["Amount ($)"]], "$        ", 4)}
                </TableCell>
                <TableCell>
                  <div
                    className={[
                      "type",
                      row.Type === "Special" ? "special" : "regular",
                    ].join(" ")}
                  >
                    {row.Type}
                  </div>
                </TableCell>
              </TableRow>
            )
          }
        })}
      </TableBody>
    </Table>
  )
}

const MobileDividendList = ({ dividendData }) => {
  return (
    <div className="dividend-mobile-list">
      {dividendData.map((row, i) => {
        if (row.Notes) {
          return (
            <div className="divided-item" key={`divident-${i}`}>
              <div className="notes">{row.Notes}</div>
            </div>
          )
        } else {
          return (
            <div className="divided-item" key={`divident-${i}`}>
              <header>
                <span className="amount">
                  {acc.formatMoney(row["Amount ($)"], { precision: 4 })}
                </span>
                <span
                  className={[
                    "type",
                    row.Type === "Special" ? "special" : "regular",
                  ].join(" ")}
                >
                  {row.Type}
                </span>
              </header>
              <ul className="data-tiles">
                <li>
                  <span className="title">Declaration Date</span>
                  <span className="data">
                    {formatDate(row["Declaration Date"])}
                  </span>
                </li>
                <li>
                  <span className="title">Record Date</span>
                  <span className="data">{formatDate(row["Record Date"])}</span>
                </li>
                <li>
                  <span className="title">Payment Date</span>
                  <span className="data">
                    {formatDate(row["Payment Date"])}
                  </span>
                </li>
              </ul>
            </div>
          )
        }
      })}
    </div>
  )
}

export default function History(props) {
  const [activeFilter, setActiveFilter] = React.useState("all")
  const [isMobile, setIsMobile] = React.useState("unknown")

  const dividendData = DividendHistory.filter(d => {
    if (d.Notes) return true
    if (activeFilter === "all") return true
    if (activeFilter === "regular") return d.Type === "Regular"
    if (activeFilter === "special") return d.Type === "Special"
    return false
  })

  // calculate if mobile or not without using the useMediaQuery hook
  // because it causes a flash of unstyled content
  const { width } = useWindowSize()

  useEffect(() => {
    setIsMobile(width <= 500)
  }, [width])

  return (
    <Layout>
      <Helmet>
        <title>Dividend History – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="COMPANY"
        header="Dividend History"
        overlayColor1="#d50000"
        overlayColor2="#da3433"
        lineColor="#f95c4f"
        bgImageSRC={atlas_lg_blackwhite}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <div className="page dividend-history-page last-page">
        <section className="page dividend-page">
          {isMobile === "unknown" && <LinearProgress />}
          {isMobile !== "unknown" && (
            <React.Fragment>
              <div className="dividend-header">
                <div className="icons">
                  <ButtonGroup className="buttonGroup">
                    <Button
                      className={
                        activeFilter === "all"
                          ? "buttonGroupActive"
                          : "buttonGroupInactive"
                      }
                      onClick={() => setActiveFilter("all")}
                    >
                      All
                    </Button>
                    <Button
                      className={
                        activeFilter === "regular"
                          ? "buttonGroupActive"
                          : "buttonGroupInactive"
                      }
                      onClick={() => setActiveFilter("regular")}
                    >
                      Regular
                    </Button>
                    <Button
                      className={
                        activeFilter === "special"
                          ? "buttonGroupActive"
                          : "buttonGroupInactive"
                      }
                      onClick={() => setActiveFilter("special")}
                    >
                      Special
                    </Button>
                  </ButtonGroup>
                  {!isMobile && (
                    <a href={DividendExcel} className="download-icon">
                      <ExcelIcon />
                      download
                    </a>
                  )}
                </div>
              </div>

              <p
                className={["declaration-notice", !isMobile ? "wide" : ""].join(
                  " "
                )}
              >
                Note: Dividends paid prior to EOG's 2-for-1 stock splits
                effective March 31, 2014 and March 1, 2005 have been adjusted.
              </p>

              {isMobile === true && (
                <MobileDividendList dividendData={dividendData} />
              )}

              {isMobile === false && (
                <FullSizeDividendGrid dividendData={dividendData} />
              )}
            </React.Fragment>
          )}
        </section>
      </div>
      <ScrollToTop />
    </Layout>
  )
}
